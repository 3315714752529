/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei/useGLTF";

export default function Model(props) {
  const group = useRef();
  const { nodes } = useGLTF("/figures_for_web.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.result__HC_8398_256.geometry}
        position={[-0.29, 0.94, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color="#ffde4d" />
      </mesh>
      <mesh
        geometry={nodes.result__HC_8400_256.geometry}
        position={[0.29, 0.94, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color="#ffde4d" />
      </mesh>
    </group>
  );
}

useGLTF.preload("/figures_for_web.gltf");
